html, 
body {
    background-color: #F9F6F4;
    height: 100%;
    width: 100%;

}

#root {
    min-height: 100%;
    min-width: 100%;
    overflow-x: hidden;
}