@font-face {
    font-family: Noe;
    src: url('../fonts/NoeDisplay/NoeDisplay-Regular.otf');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Noe;
    src: url('../fonts/NoeDisplay/NoeDisplay-Medium.eot');
    src: url('../fonts/NoeDisplay/NoeDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Noe;
    src: url('../fonts/NoeDisplay/NoeDisplay-Bold.otf');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}


@font-face {
    font-family: BrownStd;
    src: url('../fonts/BrownStd/BrownStd-Regular.otf');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: BrownStd;
    src: url('../fonts/BrownStd/BrownStd-Bold.otf');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}


@font-face {
    font-family: BCStroke;
    src: url('../fonts/BCStroke/BCStroke.woff2');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: BlackDiamond;
    src: url('../fonts/BlackDiamond/BlackDiamond.otf');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}